<template>
  <div class="vap-page">
    <Loading
      class="vasion-loading-indicator"
      :active.sync="isLoading"
      :is-full-page="false"
      :color="loaderColor"
      loader="dots"
      :background-color="loaderBackgroundColor"
    />
    <div class="vap-page-header">
      <h1>Default App</h1>
    </div>
    <div class="vap-page-content">
      <div id="app-picker">
        <div class="group-row">
          <div
            id="apps-workspace"
            class="app-group"
            :class="{ selected: defaultApp === 'workspace'}"
            @click="changeDefaultApp('workspace')"
          >
            <div class="icon-container">
              <VasionSideRailWorkspaceIcon />
            </div>
            <label class="app-label">Workspace</label>
          </div>
          <div
            v-if="canAccessReportsApp"
            id="apps-reports"
            class="app-group"
            :class="{ selected: defaultApp === 'reports'}"
            @click="changeDefaultApp('reports')"
          >
            <div class="icon-container">
              <VasionSideRailReportingIcon />
            </div>
            <label class="app-label">Reports</label>
          </div>
          <div
            id="apps-storage"
            class="app-group"
            :class="{ selected: defaultApp === 'storage'}"
            @click="changeDefaultApp('storage')"
          >
            <div class="icon-container">
              <VasionSideRailStorageIcon />
            </div>
            <label class="app-label">Storage</label>
          </div>
          <div
            v-if="canAccessCaptureApp"
            id="apps-capture"
            class="app-group"
            :class="{ selected: defaultApp === 'capture'}"
            @click="changeDefaultApp('capture')"
          >
            <div class="icon-container">
              <VasionSideRailCaptureIcon />
            </div>
            <label class="app-label">Capture</label>
          </div>
          <div
            v-if="canAccessFormsApp"
            id="apps-forms"
            class="app-group"
            :class="{ selected: defaultApp === 'forms'}"
            @click="changeDefaultApp('forms')"
          >
            <div class="icon-container">
              <VasionSideRailFormsIcon />
            </div>
            <label class="app-label">Forms</label>
          </div>
          <div
            v-if="canAccessDigitalSignature"
            id="apps-signatures"
            class="app-group"
            :class="{ selected: defaultApp === 'signature'}"
            @click="changeDefaultApp('signature')"
          >
            <div class="icon-container">
              <VasionSideRailSignatureIcon />
            </div>
            <label class="app-label">Signature</label>
          </div>
          <div
            v-if="canAccessWorkflowApp"
            id="apps-workflow"
            class="app-group"
            :class="{ selected: defaultApp === 'workflow'}"
            @click="changeDefaultApp('workflow')"
          >
            <div class="icon-container">
              <VasionSideRailWorkflowIcon />
            </div>
            <label class="app-label">Workflow</label>
          </div>
          <div
            v-if="canAccessTemplatesApp"
            id="apps-templates"
            class="app-group"
            :class="{ selected: defaultApp === 'templates'}"
            @click="changeDefaultApp('templates')"
          >
            <div class="icon-container">
              <VasionSideRailTemplatesIcon />
            </div>
            <label class="app-label">Templates</label>
          </div>
          <div
            v-if="canAccessAutomationApp"
            id="apps-automation"
            class="app-group"
            :class="{ selected: defaultApp === 'automation'}"
            @click="changeDefaultApp('automation')"
          >
            <div class="icon-container">
              <VasionSideRailAutomationIcon />
            </div>
            <label class="app-label">Automation</label>
          </div>
          <div
            v-if="canAccessAdminApp"
            id="apps-admin"
            class="app-group"
            :class="{ selected: defaultApp === 'admin'}"
            @click="changeDefaultApp('admin')"
          >
            <div class="icon-container">
              <VasionSideRailAdminIcon />
            </div>
            <label class="app-label">Admin</label>
          </div>
        </div>
      </div>
    </div>
    <VasionSnackbar
      id="profile-default-app-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay'
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'ProfileSettings',
  components: {
    Loading,
  },
  data: function () {
    return {
      defaultApp: '',
      isLoading: false,
      loaderBackgroundColor: loaderBackgroundColor,
      loaderColor: loaderColor,
      showSnackbarBool: false,
      snackbarImage: '',
      snackbarSubTitle: '',
      snackbarTitle: '',
    }
  },
  computed: {
    canAccessAdminApp() { return this.$store.getters['common/canAccessAdminApp_RolesOnly'] },
    canAccessAutomationApp() { return this.$store.getters['common/canAccessAutomationApp_RolesOnly'] },
    canAccessCaptureApp() { return this.$store.getters['common/canAccessCaptureApp_RolesOnly'] },
    canAccessCustomReports() { return this.$store.getters['common/canAccessCustomReports'] },
    canAccessDigitalSignature() { return this.$store.getters['common/canAccessDigitalSignature_RolesOnly'] },
    canAccessFormsApp() { return this.$store.getters['common/canAccessFormsApp_RolesOnly'] },
    canAccessReportsApp() { return this.$store.getters['common/canAccessReportsApp_RolesOnly'] },
    canAccessTemplatesApp() { return this.$store.getters['common/canAccessTemplatesApp_RolesOnly'] },
    canAccessWorkflowApp() { return this.$store.getters['common/showWorkflowApp'] },
    customReports() { return this.$store.state.reporting.customReports },
    originalDefaultApp() { return this.$store.state.admin.defaultApp },
  },
  watch: {
    originalDefaultApp: function (newVal) {
      this.defaultApp = newVal
    },
  },
  created: function () {
    this.$store.dispatch('admin/getUserSetting', 'defaultApp')
    this.setOriginalDefaultAppToLocal()
  },
  methods: {
    async changeDefaultApp(appName) {
      if (this.defaultApp === appName) {
        this.defaultApp = ''
      } else {
        this.defaultApp = appName
      }

      this.isLoading = true

      this.$store.dispatch('admin/defaultAppSelection', this.defaultApp)
      const response = await this.$store.dispatch('admin/setUserSetting', { settingName: 'defaultApp', settingValue: this.defaultApp})
  
      if (response && response?.data) {
        this.snackbarTitle = 'Success'
        this.snackbarSubTitle = "Settings saved"
        this.snackbarImage = true
        this.showSnackbarBool = true
      } else {
        this.snackbarTitle = 'Error'
        this.snackbarSubTitle = response?.data?.ResultMessage ? response?.data?.ResultMessage : 'Something went wrong please contact your admin'
        this.snackbarImage = false
        this.showSnackbarBool = true
      }

      setTimeout(() => { this.isLoading = false }, 500)
    },
    setOriginalDefaultAppToLocal() {
      this.defaultApp = this.originalDefaultApp
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/variables.scss';
@import '@/assets/css/light-theme-system-palette.scss';

h2 {
  font-family: $font-family-black;
  font-size: 20px;
}

#app-picker {
  @include Text;
  padding: 12px 17px;
  max-width: 760px;
  margin: auto;
}

.group-row {
  display: flex;
  justify-content: space-around;
  margin: 10px 0;
  flex-wrap: wrap;
}

.app-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 115px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  z-index: 20;
}

.icon-container {
  height: 64px;
  width: 64px;
  background-color: $btn-primary-bg;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.app-label {
  font-size: 14px;
  margin-top: 5px;
}

.group-row .app-group:hover {
  background-color: $grey-100;
}
.selected {
  background-color: $grey-100;
}
</style>
